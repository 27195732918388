export default class Api {

    static GetBaseUrl() {
        var _version = "v1.0";
        return "/api/data/" + _version + "/";
    }

    static SendEmail(data) {
        return axios.post(`https://cm.softwarerc.com/api/message/7KQGmLPR`, data);
    }

    static RetrievePosts(params) {
        return axios.get(this.GetBaseUrl() + 'blog', { params: params });
    }
}