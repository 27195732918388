import React from 'react';
import { Stack } from '@fluentui/react/lib/Stack';
import { Persona, PersonaSize } from '@fluentui/react/lib/Persona';
import { Icon, IIconStyles } from '@fluentui/react/lib/Icon';

const stackTokens = { childrenGap: 20 };

export default function PostFluent(props) {
    const iconStyles = { root: { marginRight: 5 } };
    function _onRenderSecondaryText(props){
        console.log(props);
        return (
          <div>
            <Icon iconName="calendarWorkWeek" styles={iconStyles} />
            {props.secondaryText.split('|')[0]}&ensp; 
            <Icon iconName="recent" styles={iconStyles} />{props.secondaryText.split('|')[1]}
          </div>
        );
      };

    return (
        <Stack tokens={stackTokens}>
            {props?.items.length > 0 && props.items.map((post) => (
                <div key={post.id} className="card shadow-ms mb-2 card-post">
                    <div className="card-body">
                        <h2 className="card-title display-5"><a className="link-black" href={'/blog/' + post.slug}>{post.title}</a></h2>
                        <div className="card-text">
                            <div className="row mt-0">
                                <div className="col-auto">
                                    <Persona
                                        imageUrl={post.author_thumbnail}
                                        showSecondaryText={true}
                                        text={post.author_name}
                                        secondaryText={post.publish_date+'|'+post.time_to_read}
                                        size={PersonaSize.size32}
                                        onRenderSecondaryText={_onRenderSecondaryText}
                                    />
                                </div>
                            </div>
                        </div>
                        <p className="card-text mt-2"><h4>{post.description}</h4></p>
                        <p className="mb-0">
                            {post.tags.map((tag) => (
                                <span key={tag.id} className='badge badge-secondary mr-2'>{tag.name}</span>
                            ))}
                        </p>
                        <a href={'/blog/' + post.slug} className="stretched-link"></a>
                    </div>
                </div>
            ))}

            {props?.items.length === 0 &&
                <div className='h5 align-middle text-center"'>We couldn't find any posts.</div>
            }
        </Stack>
    );
};
