import React from 'react';
import ReactDOM from 'react-dom';
import { CommandBar } from '@fluentui/react/lib/CommandBar';
import { Stack } from '@fluentui/react/lib/Stack';

// Tokens definition
const itemAlignmentsStackTokens = {
    childrenGap: 5,
    padding: 3,
};


export default function NavBarFluent() {

    return (
        <Stack tokens={itemAlignmentsStackTokens}>
            <Stack.Item align="center">
                <CommandBar
                    items={_items}
                />
            </Stack.Item>
        </Stack>
    );
};

const _items = [
    {
        key: 'blognav',
        text: 'Blog',
        iconProps: { iconName: 'Blog' },
        href: "/blog",
    },
    {
        key: 'aboutnav',
        text: 'About',
        iconProps: { iconName: 'AccountManagement' },
        href: "/about",
    },
    {
        key: 'devtoolsnav',
        text: 'DevTools',
        iconProps: { iconName: 'Toolbox' },
        href: "/devtools",
    }
];

if (document.getElementById('nav-bar-fluent')) {
    ReactDOM.render(<NavBarFluent />, document.getElementById('nav-bar-fluent'));
}