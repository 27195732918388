import React from 'react';
import ReactDOM from 'react-dom';
import { SearchBox } from '@fluentui/react/lib/SearchBox';
import { Stack } from '@fluentui/react/lib/Stack';
import { Separator } from '@fluentui/react/lib/Separator';
import { Spinner, SpinnerSize } from '@fluentui/react/lib/Spinner';
import Post from './PostFluent';
import Api from '../common/api';

const stackTokens = { childrenGap: 20 };


export default function PostsFluent() {

    const [items, setItems] = React.useState([]);
    const [loading, setLoading] = React.useState(false);
    const [searchText, setSearchText] = React.useState("");

    React.useEffect(
        async () => {
            setLoading(true);
            const posts = await Api.RetrievePosts({ search: searchText });
            setItems(posts?.data?.data || []);
            setLoading(false);
        }, [searchText]);

    return (
        <Stack tokens={stackTokens}>
            <SearchBox placeholder="Search" onSearch={newValue => setSearchText(newValue)} onClear={() => setSearchText("")} onEscape={() => setSearchText("")} />
            {loading && <Spinner size={SpinnerSize.large} />}
            {!loading && <Post items={items} />}
        </Stack>
    );
};


if (document.getElementById('posts-fluent')) {
    ReactDOM.render(<PostsFluent />, document.getElementById('posts-fluent'));
}