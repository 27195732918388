import React from 'react';
import ReactDOM from 'react-dom';
import { TextField } from '@fluentui/react/lib/TextField';
import { Stack } from '@fluentui/react/lib/Stack';
import { PrimaryButton } from '@fluentui/react/lib/Button';
import { MessageBar, MessageBarType, initializeIcons } from '@fluentui/react';
import { useBoolean } from '@fluentui/react-hooks';
import Api from '../common/api';

initializeIcons();

const stackTokens = { childrenGap: 15 };

const SuccessMessageBar = (props) => (
    <MessageBar
        messageBarType={MessageBarType.success}
        isMultiline={false}
        onDismiss={props.onClose}
        dismissButtonAriaLabel="Close"
    >
        Your message was send!
    </MessageBar>
);

const ErrorMessageBar = (props) => (
    <MessageBar
        messageBarType={MessageBarType.error}
        isMultiline={false}
        onDismiss={props.onClose}
        dismissButtonAriaLabel="Close"
    >
        Something was wrong, please try again.
    </MessageBar>
);

export default function ContactUsFluent() {
    const [name, setName] = React.useState('');
    const [email, setEmail] = React.useState('');
    const [message, setMessage] = React.useState('');
    const [nameError, setNameError] = React.useState('');
    const [emailError, setEmailError] = React.useState('');
    const [messageError, setMessageError] = React.useState('');
    const [showMessageBar, { toggle: toggleShowStatus }] = useBoolean(false);
    const [showErrorBar, { toggle: toggleShowErrorBar }] = useBoolean(false);



    const onChangeName = React.useCallback(
        (event, newValue) => {
            if (newValue) setNameError('');
            setName(newValue || '');
        },
        []
    );
    const onChangeEmail = React.useCallback(
        (event, newValue) => {
            if (newValue) setEmailError('');
            setEmail(newValue || '');
        },
        []
    );
    const onChangeMessage = React.useCallback(
        (event, newValue) => {
            if (newValue) setMessageError('');
            setMessage(newValue || '');
        },
        []
    );

    const onClickSend = React.useCallback(
        () => {
            if (showErrorBar) toggleShowErrorBar();
            if (showMessageBar) toggleShowStatus();

            let requiredMessage = "Field is required.";

            if (!name || !email || !message) {
                if (!name) { setNameError(requiredMessage); }
                if (!email) { setEmailError(requiredMessage); }
                if (!message) { setMessageError(requiredMessage); }
                return;
            }
            if (!String(email)
                .toLowerCase()
                .match(
                    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                )) {
                setEmailError("Enter a valid email.");
                return;
            }

            Api.SendEmail({
                name: name,
                email: email,
                message: message
            }).then(() => {
                toggleShowStatus();
                setEmail('');
                setMessage('');
                setName('');
            }).catch((error) => {
                toggleShowErrorBar();
            });

        },
        [name, email, message, showErrorBar, showMessageBar]
    );

    return (
        <Stack tokens={stackTokens}>
            {showMessageBar && <SuccessMessageBar onClose={toggleShowStatus} />}
            {showErrorBar && <ErrorMessageBar onClose={toggleShowErrorBar} />}
            <TextField
                label="Name"
                value={name}
                onChange={onChangeName}
                required
                errorMessage={nameError}
            />
            <TextField
                label="Email"
                value={email}
                onChange={onChangeEmail}
                required
                errorMessage={emailError}
                placeholder="name@example.com"
            />
            <TextField
                label="Message"
                multiline
                autoAdjustHeight
                value={message}
                onChange={onChangeMessage}
                required
                errorMessage={messageError}
            />

            <Stack.Item align="end" >
                <PrimaryButton text="Send" onClick={onClickSend} allowDisabledFocus />
            </Stack.Item>

        </Stack>
    );
};

if (document.getElementById('contact-us-fluent')) {
    ReactDOM.render(<ContactUsFluent />, document.getElementById('contact-us-fluent'));
}