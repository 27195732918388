
/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

require('./bootstrap');
require('jquery-confirm');
window.slugify = require('slugify');
require('./vendor/quill.js');
window.Taggle = require('taggle');
window.toastr = require('toastr');

require('jquery-easy-loading');
require('./components/AboutFluent');
require('./components/ContactUsFluent');
require('./components/CommandBarFluent');
require('./components/Timeline');
require('./components/NavBarFluent');
require('./components/PostsFluent');
