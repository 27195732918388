import Quill from 'quill/core';

import Toolbar from 'quill/modules/toolbar';
import Bubble from 'quill/themes/bubble';
import Snow from 'quill/themes/snow';

import Bold from 'quill/formats/bold';
import Italic from 'quill/formats/italic';
import Header from 'quill/formats/header';


Quill.register({
    'modules/toolbar': Toolbar,
    'themes/bubble': Bubble,
    'themes/snow': Snow,
    'formats/bold': Bold,
    'formats/italic': Italic,
    'formats/header': Header
});

require('./highlight');
window.Quill = require('quill');
//export default Quill;