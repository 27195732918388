import React from 'react';
import ReactDOM from 'react-dom';
import { CommandBar } from '@fluentui/react/lib/CommandBar';
import { Stack } from '@fluentui/react/lib/Stack';

// Tokens definition
const itemAlignmentsStackTokens = {
    childrenGap: 5,
    padding: 3,
};


export default function CommandBarFluent() {

    return (
        <Stack tokens={itemAlignmentsStackTokens}>
            <Stack.Item align="center">
                <CommandBar
                    items={_items}
                />
            </Stack.Item>
        </Stack>
    );
};

const _items = [
    {
        key: 'download',
        text: 'Download CV',
        iconProps: { iconName: 'Download' },
        onClick: () => window.print(),
    },
    {
        key: 'contactme',
        text: 'Contact Me',
        iconProps: { iconName: 'Mail' },
        href: "#contact_me"
    },
    {
        key: 'linkedIn',
        text: 'LinkedIn',
        iconProps: { iconName: 'LinkedInLogo' },
        href: "https://www.linkedin.com/in/charlesllamas",
        target: "_blank"
    }
];

if (document.getElementById('command-bar-fluent')) {
    ReactDOM.render(<CommandBarFluent />, document.getElementById('command-bar-fluent'));
}