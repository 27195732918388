import * as React from "react";
import ReactDOM from 'react-dom';
import Timeline from "@mui/lab/Timeline";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineOppositeContent from "@mui/lab/TimelineOppositeContent";
import TimelineDot from "@mui/lab/TimelineDot";
import Typography from "@mui/material/Typography";


export default function TimelineAbout() {
    return (
        <Timeline >
            <TimelineItem>
                <TimelineOppositeContent
                    sx={{ m: "auto 0" }}
                    align="right"
                    variant="body2"
                >
                    <Typography variant="h6" component="span">
                        Neoris
                    </Typography>
                </TimelineOppositeContent>
                <TimelineSeparator>
                    <TimelineConnector sx={{ bgcolor: '#28323a' }} />
                    <TimelineDot sx={{ bgcolor: '#28323a' }}></TimelineDot>
                    <TimelineConnector sx={{ bgcolor: '#28323a' }} />
                </TimelineSeparator>
                <TimelineContent sx={{ py: "12px", px: 2 }}>
                    <Typography variant="h6" component="span">
                        Senior Developer
                    </Typography>
                    <Typography>2021-Actual</Typography>
                </TimelineContent>
            </TimelineItem>
            <TimelineItem>
                <TimelineOppositeContent
                    sx={{ m: "auto 0" }}
                    align="right"
                    variant="body2"
                >
                    <Typography variant="h6" component="span">
                        Cognizant
                    </Typography>
                </TimelineOppositeContent>
                <TimelineSeparator>
                    <TimelineConnector sx={{ bgcolor: '#2f78c4' }} />
                    <TimelineDot sx={{ bgcolor: '#2f78c4' }}></TimelineDot>
                    <TimelineConnector sx={{ bgcolor: '#2f78c4' }} />
                </TimelineSeparator>
                <TimelineContent sx={{ py: "12px", px: 2 }}>
                    <Typography variant="h6" component="span">
                        Senior Developer
                    </Typography>
                    <Typography>2019-2021</Typography>
                </TimelineContent>
            </TimelineItem>
            <TimelineItem>
                <TimelineOppositeContent
                    sx={{ m: "auto 0" }}
                    align="right"
                    variant="body2"
                >
                    <Typography variant="h6" component="span">
                        Neoris
                    </Typography>
                </TimelineOppositeContent>
                <TimelineSeparator>
                    <TimelineConnector sx={{ bgcolor: '#28323a' }} />
                    <TimelineDot sx={{ bgcolor: '#28323a' }}></TimelineDot>
                    <TimelineConnector sx={{ bgcolor: '#28323a' }} />
                </TimelineSeparator>
                <TimelineContent sx={{ py: "12px", px: 2 }}>
                    <Typography variant="h6" component="span">
                        Senior Developer
                    </Typography>
                    <Typography>2017-2019</Typography>
                </TimelineContent>
            </TimelineItem>
            <TimelineItem>
                <TimelineOppositeContent
                    sx={{ m: "auto 0" }}
                    align="right"
                    variant="body2"
                >
                    <Typography variant="h6" component="span">
                        Softtek
                    </Typography>
                </TimelineOppositeContent>
                <TimelineSeparator>
                    <TimelineConnector sx={{ bgcolor: '#00cebe' }} />
                    <TimelineDot sx={{ bgcolor: '#00cebe' }}></TimelineDot>
                    <TimelineConnector sx={{ bgcolor: '#00cebe' }} />
                </TimelineSeparator>
                <TimelineContent sx={{ py: "12px", px: 2 }}>
                    <Typography variant="h6" component="span">
                        Developer
                    </Typography>
                    <Typography>2016-2017</Typography>
                </TimelineContent>
            </TimelineItem>
            <TimelineItem>
                <TimelineOppositeContent
                    sx={{ m: "auto 0" }}
                    align="right"
                    variant="body2"
                >
                    <Typography variant="h6" component="span">
                        Ho1a Innovación
                    </Typography>
                </TimelineOppositeContent>
                <TimelineSeparator>
                    <TimelineConnector sx={{ bgcolor: '#3cd52e' }} />
                    <TimelineDot sx={{ bgcolor: '#3cd52e' }}></TimelineDot>
                    <TimelineConnector sx={{ bgcolor: '#3cd52e' }} />
                </TimelineSeparator>
                <TimelineContent sx={{ py: "12px", px: 2 }}>
                    <Typography variant="h6" component="span">
                        Developer
                    </Typography>
                    <Typography>2014-2016</Typography>
                </TimelineContent>
            </TimelineItem>
            <TimelineItem>
                <TimelineOppositeContent
                    sx={{ m: "auto 0" }}
                    align="right"
                    variant="body2"
                >
                    <Typography variant="h6" component="span">
                        Syndeo
                    </Typography>
                </TimelineOppositeContent>
                <TimelineSeparator>
                    <TimelineConnector sx={{ bgcolor: '#f17527' }} />
                    <TimelineDot sx={{ bgcolor: '#f17527' }} ></TimelineDot>
                    <TimelineConnector sx={{ bgcolor: '#f17527' }} />
                </TimelineSeparator>
                <TimelineContent sx={{ py: "12px", px: 2 }}>
                    <Typography variant="h6" component="span">
                        Developer
                    </Typography>
                    <Typography>2012-2014</Typography>
                </TimelineContent>
            </TimelineItem>
        </Timeline>
    );
};

if (document.getElementById('timeline')) {
    ReactDOM.render(<TimelineAbout />, document.getElementById('timeline'));
}