import React from 'react';
import ReactDOM from 'react-dom';
import { Persona, PersonaSize, PersonaPresence } from '@fluentui/react/lib/Persona';
import { Stack } from '@fluentui/react/lib/Stack';
import { Icon, IIconStyles } from '@fluentui/react/lib/Icon';

const personaStyles = { root: { margin: '0 0 10px 0' } };
const iconStyles = { root: { marginRight: 5 } };

export default function AboutFluent() {
    const persona = {
        imageUrl: "/images/profile.jpg",
        imageInitials: 'CLL',
        text: "Charles Llamas",
        secondaryText: 'A Power Platform Developer',
        tertiaryText: '31 Years',
        optionalText: 'Guadalajara, Jalisco, MX.',
    };

    return (
        <Stack tokens={{ childrenGap: 10 }}>
            <Persona
                {...persona}
                size={PersonaSize.size120}
                presence={PersonaPresence.online}
                hidePersonaDetails={false}
                onRenderSecondaryText={_onRenderSecondaryText}
                onRenderPrimaryText={_onRenderPrimaryText}
                imageAlt="CharlesLlamas.pro"
                styles={personaStyles}
            />
        </Stack>
    );
};

function _onRenderSecondaryText(props) {
    return (
        <div><Icon iconName="PowerApps2Logo" styles={iconStyles} />
            {props.secondaryText}
        </div>
    );
}

function _onRenderPrimaryText(props) {
    return (
        <div>
            <div className="h2"><span className="d-print-none">I'm </span><strong>{props.text}</strong></div>
        </div>
    );
}

if (document.getElementById('about-fluent')) {
    ReactDOM.render(<AboutFluent />, document.getElementById('about-fluent'));
}